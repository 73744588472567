body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  overflow-x: hidden;
  color: #fff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none !important;
  box-sizing: border-box;
}

:root {
  --main-color: #00b1ff;
  --secondary-color: #f2f2f2;
  --primary-color: #000000;
  --secondary-font: "Nothing You Could Do", cursive;
  --padding-top: 100px;
  --padding-bottom: 100px;
  --transition: 0.4s;
}

/* General Style */

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

section {
  margin: 150px 0;
}

.lg-btn {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  background: var(--main-color);
  padding: 30px 60px;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
  border: 2px solid var(--main-color);
}

.lg-btn:hover {
  background: transparent;
  color: var(--main-color);
}

@media (max-width: 767px) {
  .lg-btn {
    padding: 20px 40px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .lg-btn {
    padding: 25px 40px;
  }
}

/* SECTIONHEAD STYLE */

.section__head {
  text-align: center;
  margin-bottom: 80px;
}

.section__head span {
  font-size: 26px;
  display: inline-block;
  text-align: center;
  /* width: 600px; */
  width: 100%;
  color: #777;
}

.section__head h2 {
  color: #000;
  font-size: 55px;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .section__head h2 {
    font-size: 25px;
  }
}

/* HeaderPage */

header {
  margin-top: 0rem;
  overflow: hidden;
  padding-top: 0 !important;
}

.header__container {
  width: 100%;
  height: 100%;
  margin: 120px auto;
  position: relative;
  display: grid;
  place-items: center;
}
.header__content {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 30%;
}

.header__content h2 {
  margin-bottom: 1rem;
  font-size: 40px;
  font-weight: bold;
}
.header__content h6 {
  color: #777;
  width: fit-content;
  padding: 5px;
  margin: 10px auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.header__content h6 a {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  h2 {
    font-size: 1.6rem;
  }
  .header__content {
    width: 76%;
  }
  .header__content h6 {
    width: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .header__content h2 {
    font-size: 2.2rem;
    line-height: 1.3;
  }
  .header {
    height: fit-content;
  }
  .header__content {
    width: var(--container-width-md);
    padding: 2rem 0;
  }
  .header__content h6 {
    font-size: 0.85rem;
    width: fit-content;
  }
}

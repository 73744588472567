.contact {
  margin: 300px 0;
}

.contact .contact__head {
  max-width: 450px;
  margin: 10px auto;
}

.contact .contact__head h2 {
  margin-bottom: 60px;
  position: relative;
}

.contact .contact__head h2::before {
  content: "";
  background: var(--main-color);
  position: absolute;
  bottom: 200px;
  left: 50%;
  width: 5px;
  height: 90%;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

.contact .contact__head span {
  font-size: 16px;
  color: #777;
  display: flex;
  position: relative;
}

.contact .contact__head h2::after {
  content: "";
  background: var(--main-color);
  position: absolute;
  top: 300px;
  left: 50%;
  width: 5px;
  height: 90%;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

@media (max-width: 767px) {
  .contact .contact__head h2::before {
    bottom: 100px;
    left: 50%;
    width: 5px;
    height: 100px;
  }
  .contact .contact__head h2::after {
    top: 150px;
    left: 50%;
    width: 5px;
    height: 100px;
  }
  .contact .contact__head h2 {
    margin-bottom: 40px;
  }
}

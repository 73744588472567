.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-container {
  background-color: #191f28;
  width: 100vw;
  height: 100vh;
}

/* .spinner-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: black;
  z-index: 1;
  animation: slide 4s forwards;
  -webkit-animation: slide 4s forwards;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

nav {
  height: 5rem;
  width: 100vw;
  background: #00000038;
  position: fixed;
  padding: 50px 0;
  z-index: 9999;
}

.navOnScroll {
  background: #191f28;
}

/* only show on medium and small screens */

.nav__toggle-btn {
  display: none;
}

nav .nav__container .nav__toggle-btn {
  color: #fff;
}

.nav__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  width: 4rem;
  height: 4rem;
  display: block;
}

.logo img {
  width: 200px;
  height: 200px;
}

.logo h2 {
  display: flex;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-left: 10px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.logo h2 span {
  font-weight: 200;
}

.logo h2:hover {
  color: var(--main-color);
}

.nav__links {
  display: flex;
  gap: 3.5rem;
  align-items: center;
  margin-bottom: 0 !important;
}

.nav__links a {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--secondary-color);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.nav__links a:hover {
  color: var(--main-color);
}

.active-nav {
  position: relative;
  color: var(--main-color) !important;
}

.active-nav::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background: var(--color-primary);
  position: absolute;
  left: calc(50% - 0.6rem);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  margin-top: 0.9rem;
}

nav .nav__container .phoneNum {
  background: transparent;
}

nav .nav__container .phoneNum a {
  color: #fff;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 1px;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

nav .nav__container .phoneNum a:hover {
  color: var(--main-color);
}

/* MEDIA QUERIES */

@media screen and (max-width: 1200px) {
  nav .nav__container .phoneNum {
    display: none;
  }
  .nav__toggle-btn {
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .nav__links {
    margin-top: 50px;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    gap: 0;
    perspective: 400px;
    padding-left: 0 !important;
  }
  .active-nav,
  .active-nav::after {
    display: none;
  }
  .nav__links li {
    background: #191f28;
    height: 4rem;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.5);
    animation: navAnimation 600ms ease forwards;
    -webkit-animation: navAnimation 600ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
  }
  .nav__links li:nth-child(2) {
    animation-delay: 200ms;
  }
  .nav__links li:nth-child(3) {
    animation-delay: 400ms;
  }
  .nav__links li:nth-child(4) {
    animation-delay: 600ms;
  }
  .nav__links li:nth-child(5) {
    animation-delay: 800ms;
  }
  .nav__links li:nth-child(6) {
    animation-delay: 1s;
  }
  .nav__links li:nth-child(7) {
    animation-delay: 1.2s;
  }
  @keyframes navAnimation {
    to {
      transform: rotateX(0);
      opacity: 1;
      -webkit-transform: rotateX(0);
      -moz-transform: rotateX(0);
      -ms-transform: rotateX(0);
      -o-transform: rotateX(0);
    }
  }
  .nav__links li a {
    background-color: var(--color-primary);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 5rem 1rem 3rem;
  }
  .show__nav {
    display: flex;
  }
  .hide__nav {
    display: none;
  }
}
